<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';

	export default {
		name: "ProjectPriceList",
		extends: Base,
		data() {
			return {
				Name: "ProjectPriceList",
				project: {},
				link:'',
				input:{file:'',}
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
			setTimeout(()=>{
				$('.project').find('.all').attr('selected',true)
			},2000)			
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.project'() {
				this.search()
			},
		},
		methods:{
			importData(){
				swal({
					  title: "Are you sure?",
					  text: "You will add data, make sure the project id is correct!",
					  icon: "warning",
					  buttons: true,
					  dangerMode: false,
					  closeOnClickOutside: false
					})
					.then((getData) => {
					  if (getData) {
					    this.input.type='storeData'
					    if(this.input.file){
					    	setTimeout(()=>{
								this.initLoader()
							},100)
					    	this.loadOverlay=true
					    }
						BOGen.apirest('/' + this.Name, this.input, (err, resp) => {
				          if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.info')
				          this.loadOverlay=false
				          this.refreshData()    
				          this.input={} 
				          swal("Success","Success upload data!","success")   
				        }, "POST");
					  } else {
					  	this.input={} 
					    return;
					  }
					});
			},
			download(lnk){
				window.open(lnk, '_blank');
			}
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}}</h5>
								</div>
								<div class="col-sm-3">
									<select v-model="filter.project" @change="search()" class="form-control project">
										<option value="" class="all">-- Filter Project --</option>
										<option :value="v.mp_id" v-for="(v,k) in project">{{v.mp_title}}</option>
									</select>
								</div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning btn-block">Reset</router-link>
								</div>
							</div>
						</VForm>
						<div class="custom_legend">
							<VForm @resp="importData" class="row align-items-center" >
								<div class="info col-12 info"></div>
								<div class="col-4"><h5 class="card-title">Import Data</h5></div>
								<div class="col-2 text-right">
									<a class="btn btn-primary" v-tooltip="'Download Template'" @click="download(link)" style="color: #fff;"><i class="icon-cloud-download"></i>&nbsp;Template</a>
								</div>
								<div class="col-md-4">
									<Uploader name="file" noHelp isFrontend isImportExcel type="import" uploadType="Uploader" class="importExcel" v-model="input.file"></Uploader>		
								</div>
								<div class="col-md-2">
									<button type="submit" class="btn btn-loading btn-success btn-block"><i class="icon-check mr-2"></i>Import</button>
								</div>
								<div class="col-12" style="padding: 20px;">
									<div class="alert alert-info" style="margin: 0px;">
										<h5>ID Proyek:</h5>
										<ul>
											<li v-for="(v,k) in project">{{v.mp_id+' => Untuk '+v.mp_title}}</li>
										</ul>
									</div>
								</div>
							</VForm>
						</div>
					</div>
					<div class="table-responsive ajax-table" v-dragscroll>
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>Project</th>
									<th>Blok</th>
									<th>Kavling</th>
									<th>Status</th>
									<th>Jumlah Lantai</th>
									<th>Luas Tanah (M<sup>2</sup>)</th>
									<th>Luas Bangunan (M<sup>2</sup>)</th>
									<th>Posisi</th>
									<th>Lebar Muka</th>
									<th>KPR</th>
									<th>DP Normal</th>
									<th>DP Promo</th>
									<th>Flafond</th>
									<th>Tenor 5 Th</th>
									<th>Tenor 10 Th</th>
									<th>Tenor 15 Th</th>
									<th>Tenor 20 Th</th>
									<th>Tenor 25 Th</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.project}}</td>
								    <td>{{v.mpl_blok}}</td>
								    <td>{{v.mpl_kavling}}</td>
										<td>
											<StatusLabel :status="v.status"></StatusLabel>
											<label v-if="v.mpl_sold_status == 'Y'" class='label label-danger'>Sold Out</label>
											<label v-if="v.mpl_sold_status == 'N'" class='label label-info'>Available</label>
										</td>
								    <td>{{v.mpl_floor}}</td>
								    <td>{{v.mpl_lt}}</td>
								    <td>{{v.mpl_lb}}</td>
								    <td>{{v.mpl_posisi?v.mpl_posisi:'-'}}</td>
								    <td>{{v.mpl_lebar_muka}}</td>
								    <td>{{(v.mpl_price_kpr).format()}}</td>
								    <td>{{(v.mpl_dp_normal).format()}}</td>
								    <td>{{(v.mpl_dp_promo).format()}}</td>
								    <td>{{(v.mpl_plafond).format()}}</td>
								    <td>{{(v.mpl_tenor_5).format()}}</td>
								    <td>{{(v.mpl_tenor_10).format()}}</td>
								    <td>{{(v.mpl_tenor_15).format()}}</td>
								    <td>{{(v.mpl_tenor_20).format()}}</td>
								    <td>{{(v.mpl_tenor_25).format()}}</td>
									<td class="btn-action">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'">
											<i class="ti-marker-alt"></i>
										</router-link>
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'">
											<i class="ti-trash"></i>
										</a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card-body">
						<Pagination class="mb-0 justify-content-end" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-4">
							<BoField name="mpl_project" mandatory>
								<select v-model='row.mpl_project' class="form-control">
									<option :value="v.mp_id" v-for="(v,k) in project">{{v.mp_title}}</option>
								</select>
							</BoField>
							<BoField name="mpl_blok" v-model="row.mpl_blok"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_kavling" v-model="row.mpl_kavling"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_floor" v-model="row.mpl_floor"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_lb" v-model="row.mpl_lb"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_lt" v-model="row.mpl_lt"></BoField>
						</div>
						<div class="col-4">
							<BoField name="mpl_posisi" v-model="row.mpl_posisi"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_lebar_muka" v-model="row.mpl_lebar_muka"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_price_kpr" v-model="row.mpl_price_kpr"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_dp_normal" v-model="row.mpl_dp_normal"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_dp_promo" v-model="row.mpl_dp_promo"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_plafond" v-model="row.mpl_plafond"></BoField>
						</div>
						<div class="col-4">
							<BoField :attr="{type:'number'}" name="mpl_tenor_5" v-model="row.mpl_tenor_5"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_tenor_10" v-model="row.mpl_tenor_10"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_tenor_15" v-model="row.mpl_tenor_15"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_tenor_20" v-model="row.mpl_tenor_20"></BoField>
							<BoField :attr="{type:'number'}" name="mpl_tenor_25" v-model="row.mpl_tenor_25"></BoField>
							<BoField name="mpl_is_active">
								<div class="row">
									<radio name="mpl_is_active" v-model="row.mpl_is_active" option="Y"
										:attr="validation('mpl_is_active')">Active</radio>
									<radio name="mpl_is_active" v-model="row.mpl_is_active" option="N">Inactive</radio>
								</div>
							</BoField>
							<BoField name="mpl_sold_status">
								<div class="row">
									<radio name="mpl_sold_status" v-model="row.mpl_sold_status" option="Y"
										:attr="validation('mpl_sold_status')">Sold Out</radio>
									<radio name="mpl_sold_status" v-model="row.mpl_sold_status" option="N">Available</radio>
								</div>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-rounded btn-loading">Save</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>